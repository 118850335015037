<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h4 class="text-blue-900 text-lg font-semibold">
        Cashback Activation Portal
      </h4>
      <p class="text-lg mt-10">Activate Cashback</p>
      <p class="text-sm">Current: {{ this.defaultCashbackState }}</p>
      <form @submit.prevent="saveCashbackSettings">
        <div class="grid grid-cols-2 gap-5">
          <div class="flex flex-col d-block">
            <div class="w-full">
              <input type="radio" value="activate" />
              <label for="password" class="text-xs ml-1">Activate</label>
            </div>
            <div class="w-full">
              <input type="radio" value="activate" />
              <label for="password" class="text-xs ml-1">Deactivate</label>
            </div>
          </div>
        </div>
        <p class="text-lg mt-10">Parameters</p>
        <div class="grid grid-cols-2 gap-5">
          <div class="flex flex-col pt-4 d-block">
            <div class="w-full">
              <label for="password" class="text-xs"
                >Maximum Monthly Cashback: *</label
              >
              <input
                type="text"
                placeholder="N"
                class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
          <div class="flex flex-col pt-4 d-block">
            <div class="w-full">
              <label for="password" class="text-xs"
                >Fixed Cashback Amount *</label
              >
              <input
                type="text"
                placeholder="N"
                class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
          <div class="flex flex-col pt-4 d-block">
            <div class="w-full">
              <label for="password" class="text-xs">% Cashback Amount *</label>
              <input
                type="text"
                placeholder="N"
                class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
        </div>
        <p class="text-lg mt-10">Activate Parameter</p>
        <p class="text-sm">Current: {{ this.defaultParameter }}</p>
        <div class="grid grid-cols-2 gap-5">
          <div class="flex flex-col d-block">
            <div class="w-full">
              <input
                type="radio"
                v-model="cashbackParam"
                value="fixed-cashback"
                id="fixed-cashback"
              />
              <label for="fixed-cashback" class="text-xs ml-1"
                >Fixed Cashback</label
              >
            </div>
            <div class="w-full">
              <input
                type="radio"
                v-model="cashbackParam"
                value="percent-cashback"
              />
              <label for="password" class="text-xs ml-1">% Cashback</label>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="block uppercase shadow bg-blue-600 mt-5 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
        >
          Save
        </button>
      </form>
    </div>
  </main>
</template>

<script>
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      isLoading: false,
      provider: [],
      defaultCashbackState: "INACTIVE",
      defaultParameter: "Fixed Cashback",
      cashbackParam: "",
    };
  },
  components: {
    Loading,
  },
  computed: {},
  async mounted() {
    // this.isLoading = true;
    // let payload = {
    //   name: "DEFAULT",
    // };
    // let res = await this.$store.dispatch("FETCH_TRANSFER_CONFIG", payload);
    // if (res.status == true) {
    //   this.defaultProvider = res.data.items[0].value;
    // }
    // this.isLoading = false;
  },
  methods: {
    async saveCashbackSettings() {
      //   if (this.terminal_provider_id == "") {
      //     alert("Select a provider");
      //     return;
      //   }
      //   let res = confirm("Are you sure you want to do this?");
      //   if (res) {
      //     this.isLoading = true;
      //     this.payload.value = this.terminal_provider_id;
      //     let res = await this.$store.dispatch(
      //       "UPDATE_FUNDS_TRANSFER_CONFIG",
      //       this.payload
      //     );
      //     if (res.status) {
      //       this.isLoading = false;
      //       this.$router.go();
      //     } else {
      //       this.isLoading = false;
      //       alert(res.error);
      //     }
      //   }
    },
  },
  async created() {
    // this.isLoading = true;
    // let providers = await this.$store.dispatch("FETCH_ALL_PROVIDERS");
    // this.providers = providers.data.items;
  },
};
</script>

<style></style>
